// StockTickerPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

axios.defaults.baseURL = 'https://watchlist-server-six.vercel.app';

function StockTickerPage() {
  const [tickers, setTickers] = useState([]);
  const [newTicker, setNewTicker] = useState('');

  useEffect(() => {
    fetchTickers();
  }, []);

  const fetchTickers = async () => {
    try {
      const { data } = await axios.get('/api/tickers');
      setTickers(data);
    } catch (error) {
      console.error('Error fetching tickers:', error);
    }
  };

  const handleAddTicker = async () => {
    try {
      await axios.post('/api/add-ticker', { ticker: newTicker });
      setNewTicker('');
      fetchTickers();
    } catch (error) {
      console.error('Error adding ticker:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
    }
  };

  const handleUpdateTickers = async () => {
    try {
      await axios.post('/api/update-tickers');
      fetchTickers();
    } catch (error) {
      console.error('Error updating tickers:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Stock Tickers</h1>
      <div className="mb-4">
        <input
          type="text"
          value={newTicker}
          onChange={(e) => setNewTicker(e.target.value)}
          placeholder="Enter new ticker"
          className="border p-2 mr-2"
        />
        <button
          onClick={handleAddTicker}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Add Ticker
        </button>
      </div>
      <button
        onClick={handleUpdateTickers}
        className="bg-green-500 text-white p-2 rounded mb-4"
      >
        Update All Tickers
      </button>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Ticker</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">1d</th>
            <th className="px-4 py-2">5d</th>
            <th className="px-4 py-2">1m</th>
            <th className="px-4 py-2">1y</th>
            <th className="px-4 py-2">Date Added</th>
            <th className="px-4 py-2">Price Added</th>
            <th className="px-4 py-2">Added Change</th>
          </tr>
        </thead>
        <tbody>
          {tickers.map((ticker) => (
            <tr key={ticker.ticker}>
              <td className="border px-4 py-2">{ticker.ticker}</td>
              <td className="border px-4 py-2">{ticker.name}</td>
              <td className="border px-4 py-2">{ticker.price}</td>
              <td className="border px-4 py-2">{ticker['1d']}%</td>
              <td className="border px-4 py-2">{ticker['5d']}%</td>
              <td className="border px-4 py-2">{ticker['1m']}%</td>
              <td className="border px-4 py-2">{ticker['1y']}%</td>
              <td className="border px-4 py-2">{new Date(ticker.date_added).toLocaleDateString()}</td>
              <td className="border px-4 py-2">{ticker.price_added}</td>
              <td className="border px-4 py-2">{ticker.added_change}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StockTickerPage;
