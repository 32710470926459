import React from 'react';
import './CollageComponent.css'; // Import CSS for styling

// Import all images
import breakfast from './collageimages/breakfast.png'
import cobra from './collageimages/cobra.png';
import get from './collageimages/get.jpg';
import gw from './collageimages/gw.jpg';
import toiletpaper from './collageimages/toiletpaper.png';
import tony from './collageimages/tony.png';
import burry from './collageimages/burry.png';
import tahm from './collageimages/tahm.png';
import Skhreli from './collageimages/Skhreli.png';
import starwars from './collageimages/starwars.png';
import shawshank from './collageimages/shawshank.png';
import family from './collageimages/family.png';
import gf1 from './collageimages/gf1.png';
import tpb from './collageimages/tpb.png';
import steve from './collageimages/steve.png';
import kingofny from './collageimages/kingofny.png';
import larry from './collageimages/larry.png';
import poker from './collageimages/poker.png';
import saddam from './collageimages/saddam.png';
import heat from './collageimages/heat.png';
import hash from './collageimages/hash.png';
import storage from './collageimages/storage.png';
import gate from './collageimages/gate.png';
import woods from './collageimages/woods.png';
import notourist from './collageimages/notourist.png';
import rugmouse from './collageimages/rugmouse.png';
import redcarpet from './collageimages/redcarpet.png';
import miso from './collageimages/miso.png';
import tates from './collageimages/tates.png';
import clone from './collageimages/clone.png';
import cuban from './collageimages/cuban.png';
import mandalorians from './collageimages/mandalorians.png';
import soprano from './collageimages/soprano.png';
import mefr from './collageimages/mefr.png';
import yessuh from './collageimages/yessuh.png';
import mrcnoir from './collageimages/mrcnoir.png';
import lilbro from './collageimages/lilbro.png';
import bookman from './collageimages/bookman.png';
import style from './collageimages/style.png';
import stussy from './collageimages/stussy.png';
import meme from './collageimages/meme.png';
import kingdom from './collageimages/kingdom.png';
import kingdom2 from './collageimages/kingdom2.png';
import culturewar from './collageimages/culturewar.png';
import carlito from './collageimages/carlito.png';
import ship from './collageimages/ship.png';
import tateW from './collageimages/tateW.png';
import surrealist from './collageimages/surrealist.png';
import img1 from './collageimages/img1.png';
import img2 from './collageimages/img2.png';
import img3 from './collageimages/img3.png';
import img4 from './collageimages/img4.png';
import img5 from './collageimages/img5.png';
import img6 from './collageimages/img6.png';
import img7 from './collageimages/img7.png';
import img8 from './collageimages/img8.png';
import img9 from './collageimages/img9.png';
import img10 from './collageimages/img10.png';
import img11 from './collageimages/img11.png';
import img12 from './collageimages/img12.png';
import img13 from './collageimages/img13.png';
import img14 from './collageimages/img14.png';
import img15 from './collageimages/img15.png';
import img16 from './collageimages/img16.png';
import img17 from './collageimages/img17.png';
import img18 from './collageimages/img18.png';
import img19 from './collageimages/img19.png';
import img20 from './collageimages/img20.png';
import img21 from './collageimages/img21.png';
import img22 from './collageimages/img22.png';
import img23 from './collageimages/img23.png';
import img24 from './collageimages/img24.png';
import img25 from './collageimages/img25.png';
import img26 from './collageimages/img26.png';
import img27 from './collageimages/img27.jpg';
import img28 from './collageimages/img28.png';
import img29 from './collageimages/img29.png';
import img30 from './collageimages/img30.jpg';
import img31 from './collageimages/img31.png';
import img32 from './collageimages/img32.png';
import img33 from './collageimages/img33.jpg';
import img34 from './collageimages/img34.png';
import img35 from './collageimages/img35.png';
import img36 from './collageimages/img36.png';
import img37 from './collageimages/img37.png';
import img38 from './collageimages/img38.png';
import img39 from './collageimages/img39.png';
import img40 from './collageimages/img40.png';
import img41 from './collageimages/img41.png';
import img42 from './collageimages/img42.png';
import img43 from './collageimages/img43.png';
import img44 from './collageimages/img44.png';
import img46 from './collageimages/img46.png';
import img47 from './collageimages/img47.png';
import img48 from './collageimages/img48.png';
import img49 from './collageimages/img49.png';
import img50 from './collageimages/img50.png';
import img51 from './collageimages/img51.png';
import img52 from './collageimages/img52.png';


const CollageComponent = () => {


    return (
        <>
            <hr className="t" />
            <div className="nav">
                <div className="container" id="image-container">
                <img src={breakfast} alt="Breakfast" />,
                    <img src={cobra}alt="Breakfast" />,
                    <img src={get}alt="Breakfast" />,
                    <img src={gw}alt="Breakfast" />,
                    <img src={toiletpaper}alt="Breakfast" />,
                    <img src={tony}alt="Breakfast" />,
                    <img src={burry}alt="Breakfast" />,
                    <img src={tahm}alt="Breakfast" />,
                    <img src={Skhreli}alt="Breakfast" />,
                    <img src={starwars}alt="Breakfast" />,
                    <img src={shawshank}alt="Breakfast" />,
                    <img src={family}alt="Breakfast" />,
                    <img src={gf1}alt="Breakfast" />,
                    <img src={tpb}alt="Breakfast" />,
                    <img src={steve}alt="Breakfast" />,
                    <img src={kingofny}alt="Breakfast" />,
                    <img src={larry}alt="Breakfast" />,
                    <img src={poker}alt="Breakfast" />,
                    <img src={saddam}alt="Breakfast" />,
                    <img src={heat}alt="Breakfast" />,
                    <img src={hash}alt="Breakfast" />,
                    <img src={storage}alt="Breakfast" />,
                    <img src={gate}alt="Breakfast" />,
                    <img src={woods}alt="Breakfast" />,
                    <img src={notourist}alt="Breakfast" />,
                    <img src={rugmouse}alt="Breakfast" />,
                    <img src={redcarpet}alt="Breakfast" />,
                    <img src={miso}alt="Breakfast" />,
                    <img src={tates}alt="Breakfast" />,
                    <img src={clone}alt="Breakfast" />,
                    <img src={cuban}alt="Breakfast" />,
                    <img src={mandalorians}alt="Breakfast" />,
                    <img src={soprano}alt="Breakfast" />,
                    <img src={mefr}alt="Breakfast" />,
                    <img src={yessuh}alt="Breakfast" />,
                    <img src={mrcnoir}alt="Breakfast" />,
                    <img src={lilbro}alt="Breakfast" />,
                    <img src={bookman}alt="Breakfast" />,
                    <img src={style}alt="Breakfast" />,
                    <img src={stussy}alt="Breakfast" />,
                    <img src={meme}alt="Breakfast" />,
                    <img src={kingdom}alt="Breakfast" />,
                    <img src={kingdom2}alt="Breakfast" />,
                    <img src={culturewar}alt="Breakfast" />,
                    <img src={carlito}alt="Breakfast" />,
                    <img src={ship}alt="Breakfast" />,
                    <img src={tateW}alt="Breakfast" />,
                    <img src={surrealist}alt="Breakfast" />,
                    <img src={img1}alt="Breakfast" />,
                    <img src={img2}alt="Breakfast" />,
                    <img src={img3}alt="Breakfast" />,
                    <img src={img4}alt="Breakfast" />,
                    <img src={img5}alt="Breakfast" />,
                    <img src={img6}alt="Breakfast" />,
                    <img src={img7}alt="Breakfast" />,
                    <img src={img8}alt="Breakfast" />,
                    <img src={img9}alt="Breakfast" />,
                    <img src={img10}alt="Breakfast" />,
                    <img src={img11}alt="Breakfast" />,
                    <img src={img12}alt="Breakfast" />,
                    <img src={img13}alt="Breakfast" />,
                    <img src={img14}alt="Breakfast" />,
                    <img src={img15}alt="Breakfast" />,
                    <img src={img16}alt="Breakfast" />,
                    <img src={img17}alt="Breakfast" />,
                    <img src={img18}alt="Breakfast" />,
                    <img src={img19}alt="Breakfast" />,
                    <img src={img20}alt="Breakfast" />,
                    <img src={img21}alt="Breakfast" />,
                    <img src={img22}alt="Breakfast" />,
                    <img src={img23}alt="Breakfast" />,
                    <img src={img24}alt="Breakfast" />,
                    <img src={img25}alt="Breakfast" />,
                    <img src={img26}alt="Breakfast" />,
                    <img src={img27}alt="Breakfast" />,
                    <img src={img28}alt="Breakfast" />,
                    <img src={img29}alt="Breakfast" />,
                    <img src={img30}alt="Breakfast" />,
                    <img src={img31}alt="Breakfast" />,
                    <img src={img32}alt="Breakfast" />,
                    <img src={img33}alt="Breakfast" />,
                    <img src={img34}alt="Breakfast" />,
                    <img src={img35}alt="Breakfast" />,
                    <img src={img36}alt="Breakfast" />,
                    <img src={img37}alt="Breakfast" />,
                    <img src={img38}alt="Breakfast" />,
                    <img src={img39}alt="Breakfast" />,
                    <img src={img40}alt="Breakfast" />,
                    <img src={img41}alt="Breakfast" />,
                    <img src={img42}alt="Breakfast" />,
                    <img src={img43}alt="Breakfast" />,
                    <img src={img44}alt="Breakfast" />,
                    <img src={img46}alt="Breakfast" />,
                    <img src={img47}alt="Breakfast" />,
                    <img src={img48}alt="Breakfast" />,
                    <img src={img49}alt="Breakfast" />,
                    <img src={img50} alt="Breakfast"/>
                    <img src={img51} alt="Breakfast"/>
                    <img src={img52} alt="Breakfast"/>
                </div>
            </div>
        </>
    );
};

export default CollageComponent;
