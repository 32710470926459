// App.js
import React from 'react';
import StockTickerPage from './StockTickerPage';
import CollageComponent from './CollageComponent';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StockTickerPage />} />
        <Route path="/collage" element={<CollageComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
